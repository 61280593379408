import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue")
  },
  {
    path: "/news",
    name: "News",
    component: () => import("../views/News.vue")
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("../views/Contact.vue")
  },
  {
    path: "/data-privacy",
    name: "DataPrivacy",
    component: () => import("../views/DataPrivacy.vue")
  },
  {
    path: "/legal-notices",
    name: "LegalNotices",
    component: () => import("../views/LegalNotices.vue")
  },
  {
    path: "/post/:post",
    name: "BlogItem",
    component: () => import("../views/BlogItem.vue")
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {

      return { el: to.hash, behavior: 'smooth' }
    }
    return { top: 0 }
  }
})

export default router