<template>
    <footer>
        <div class="footer-container">
            <div class="row">
                <div class="col-33">
                    <div class="footer-menu">
                        <router-link :to="{name : 'Home'}" class="menu-item">{{ $t("menu.home")}}</router-link>
                        <router-link :to="{name : 'News'}" class="menu-item">{{ $t("menu.news")}}</router-link>
                        <router-link :to="{name : 'Contact'}" class="menu-item">{{ $t("menu.contact")}}</router-link>
                    </div>
                </div>
                <div class="col-33">
                    <div class="footer-menu">
                        <a href="" target="_blank" class="menu-item">Safegroup</a>
                    </div>
                </div>
                <div class="col-33">
                    <div class="footer-menu">
                        <h3>Safe<span>medical</span></h3>
                        <p>
                            80 rue Montepy - ZA de Montepy<br />
                            69210 Fleurieux-sur-L’Arbresle<br />
                        </p>
                        <div class="footer-icon-item">
                            <i class="fa-solid fa-phone footer-icon"></i> 
                            <p>+33 (0) 4 27 44 73 80</p>
                        </div>
                        <div class="footer-icon-item">
                            <i class="fa-solid fa-print footer-icon"></i> 
                            <p>+33 (0) 1 34 21 12 00</p>
                        </div>
                        <div class="footer-rs">
                            <a href="https://www.linkedin.com/company/lci-medical/?originalSubdomain=fr" target="_blank">
                                <i class="fa-brands footer-icon fa-linkedin"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="logo-container">
                <img src="./../assets/logo-safe-medical.png" alt="logo footer" />
            </div>
        </div>
        <div class="footer-infos">
            <p>Copyright © {{currentYear}}, SAFE MEDICAL. All Rights Reserved.</p>
            <div class="footer-links">
                <router-link :to="{name : 'LegalNotices'}" class="footer-link">{{ $t("menu.legalNotices")}}</router-link>
                -
                <router-link :to="{name : 'DataPrivacy'}" class="footer-link">{{ $t("menu.dataPrivacy")}}</router-link>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name : "Footer",
    data() {
        return {
            currentYear : new Date().getFullYear()
        }
    }
}
</script>

<style lang="scss" scoped>
    footer {
        background:linear-gradient(#fff, #ccc);
    }

    .footer-infos {
        border-top:1px solid #fff;
        padding:20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #818c8f;
    }

    .logo-container img {
        max-width: 95%;
        margin-top:20px;
    }

    .footer-infos p {
        margin: 0;
        color:#fff;
        text-align: center;
        font-size:13px;
        line-height: 1.3em;
    }

    .footer-links {
        margin-top:10px;
        color:#fff;
        text-align: center;
        font-size:13px;
    }

    .footer-link {
        color:#fff;
        text-decoration: none;
    }

    .footer-link:hover {
        text-decoration: underline;
    }

    .footer-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding:40px 0;
        width: $box;
        max-width: 95%;
        margin:0 auto;
    }

    .col-33 {
        display: flex;
        flex-direction: column;
        align-self: stretch;
        border-left:2px solid $mainColor;   
    }

    .footer-menu  {
        display: flex;
        flex-direction: column;
        padding-left:20px;        
    }

    .menu-item {
        color:$black;
        text-decoration: none;
        margin:8px 0;
        font-weight: 300;
        font-size:15px;
        transition: 0.5s;
    }

    .menu-item:hover {
        color:$mainColor;
    }

    h3 {
        color:$mainColor;
        font-weight: 300;
        font-size:22px;
        line-height:1.3em;
        margin:8px 0 20px 0;
    }

    h3 span {
        color: $black;
    }

    .footer-menu p, .footer-icon-item p {
        color:$black;
        font-weight: 300;
        font-size:15px;
        line-height:1.8em;
    }

    .footer-icon-item p {
        margin:0;
    }

    .footer-icon-item {
        display: flex;
        align-items: center;
        margin:10px 0;
    }

    .footer-icon {
        font-size:25px;
        color:$mainColor;
        margin-right: 10px;
        font-weight: 300;
    }

    .footer-rs {
        margin-top:20px;
    }

    .footer-rs .footer-icon {
        margin-right:10px;
        transition: 0.5s;
        font-size:30px;
    }

    .footer-rs .footer-icon:hover {
        color:$black;
    }

    @media screen and (max-width:900px) {
        .row {
            flex-direction: column;
        }

        .col-33 {
            width:98%;
            border: none;
            align-items: center;
        }

        .footer-menu {
            align-items: center;
        }

        .footer-menu p {
            text-align: center;
        }

        .col-33:nth-child(1), .col-33:nth-child(2) {
            display: none;
        }
    }
</style>